import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

const routes = [
    
    {
        path: '/recuperar-senha/:hash',
        name: 'Recuperar Senha',
        component: () => import('./pages/Recuperar.vue')
    },
    {
        path: '/dashboard',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            {
                path: '/usuarios',
                name: 'Usuários',
                component: () => import('./paginas/usuarios/index.vue')
            },
            {
                path: '/fornecedores',
                name: 'Fornecedores',
                component: () => import('./paginas/fornecedores/index.vue')
            },
            {
                path: '/importar-planilha',
                name: 'Importar Planilha',
                component: () => import('./paginas/importar/index.vue')
            },
            {
                path: '/empresas',
                name: 'Empresas',
                component: () => import('./paginas/empresas/index.vue')
            },
            {
                path: '/tipos-planilhas',
                name: 'Tipos de Planilhas',
                component: () => import('./paginas/planilhas/index.vue')
            },
            {
                path: '/unidades',
                name: 'Unidades',
                component: () => import('./paginas/unidades/index.vue')
            },
            {
                path: '/areas',
                name: 'Áreas',
                component: () => import('./paginas/areas/index.vue')
            },
            {
                path: '/tipos',
                name: 'Tipos',
                component: () => import('./paginas/tipos/index.vue')
            },
            {
                path: '/faturas/tipos',
                name: 'Tipos Faturas',
                component: () => import('./paginas/tipos_faturas/index.vue')
            },
            {
                path: '/regional',
                name: 'Regional',
                component: () => import('./paginas/regional/index.vue')
            },
            {
                path: '/faturas',
                name: 'Faturas',
                component: () => import('./paginas/faturas/index.vue')
            },
            {
                path: '/relatorios/faturas',
                name: 'Relatório de Faturas',
                component: () => import('./paginas/relatorios/faturas.vue')
            },
            {
                path: '/relatorios/base',
                name: 'Relatório de Base Cadastral',
                component: () => import('./paginas/relatorios/base.vue')
            },
            {
                path: '/energia',
                name: 'Relatório de Energia Injetada',
                component: () => import('./paginas/energia/energia.vue')
            },
            {
                path: '/importar',
                name: 'Importar de Energia Injetada',
                component: () => import('./paginas/importar/index.vue')
            },
            {
                path: '/base1',
                name: 'Base 1 - Importada',
                component: () => import('./paginas/planilhas/base1.vue')
            },
            {
                path: '/base2',
                name: 'Base 2 - Importada',
                component: () => import('./paginas/planilhas/base2.vue')
            },
            {
                path: '/base3',
                name: 'Base 3 - Importada',
                component: () => import('./paginas/planilhas/base3.vue')
            },
            {
                path: '/fornecedor-planilha',
                name: 'Importar Planilha Fornecedor',
                component: () => import('./paginas/fornecedores/importar.vue')
            },
            {
                path: '/planilha-importada',
                name: 'Planilha Fornecedor Importada',
                component: () => import('./paginas/planilhas/fornecedor.vue')
            },

            {
                path: '/consolidacao',
                name: 'Relatório Consolidação',
                component: () => import('./paginas/relatorios/consolidacao.vue')
            },

            {
                path: '/liberar-pagamentos',
                name: 'Liberar Pagamentos',
                component: () => import('./paginas/relatorios/liberarPagamentos.vue')
            },
            {
                path: '/validar-pagamentos',
                name: 'Validar Pagamentos',
                component: () => import('./paginas/relatorios/validarPagamentos.vue')
            },
            {
                path: '/planilha/:id',
                name: 'Gestão de Planilhas',
                component: () => import('./paginas/planilhas/gestao-planilhas.vue')
            },
            
            {
                path: '/menu',
                component: () => import('./components/MenuDemo.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('./components/menu/PersonalDemo.vue')
                    },
                    {
                        path: '/menu/seat',
                        component: () => import('./components/menu/SeatDemo.vue')
                    },
                    {
                        path: '/menu/payment',
                        component: () => import('./components/menu/PaymentDemo.vue')
                    },
                    {
                        path: '/menu/confirmation',
                        component: () => import('./components/menu/ConfirmationDemo.vue')
                    },
                ],
            },
            {
                path: '/messages',
                name: 'messages',
                component: () => import('./components/MessagesDemo.vue')
            },
            {
                path: '/file',
                name: 'file',
                component: () => import('./components/FileDemo.vue')
            },
            {
                path: '/chart',
                name: 'chart',
                component: () => import('./components/ChartDemo.vue')
            },
            {
                path: '/misc',
                name: 'misc',
                component: () => import('./components/MiscDemo.vue')
            },
            {
                path: '/crud',
                name: 'crud',
                component: () => import('./pages/CrudDemo.vue')
            },
            {
                path: '/timeline',
                name: 'timeline',
                component: () => import('./pages/TimelineDemo.vue')
            },
            {
                path: '/empty',
                name: 'empty',
                component: () => import('./components/EmptyPage.vue')
            },
            {
                path: '/documentation',
                name: 'documentation',
                component: () => import('./components/Documentation.vue')
            },
            {
                path: '/blocks',
                name: 'blocks',
                component: () => import('./components/BlocksDemo.vue')
            },
            {
                path: '/icons',
                name: 'icons',
                component: () => import('./components/IconsDemo.vue')
            }
        ]
    },
    {
        path: '/',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
   
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
    { path: '/:pathMatch(.*)*', component: () => import('./pages/NotFound.vue') },

];

const router = createRouter({
    mode: "history",
    history: createWebHashHistory(),
    routes,
});

export default router;
