<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />

    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu_ame" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" />
    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppConfig from "./AppConfig.vue";
import AppFooter from "./AppFooter.vue";

export default {
  emits: ["change-theme"],
  data() {
    return {
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [],
      menu_empresa: [
        {
          label: "Workflow Compartilhamento",
          icon: "pi pi-fw pi-home",
          to: "/dashboard",
          items: [
            {
              label: "Home",
              icon: "pi pi-fw pi-home",
              to: "/dashboard",
            },
          ],
        },
        {
          label: "Relatórios",
          icon: "pi pi-fw pi-sitemap",
          items: [

            { label: "Base Cadastral", icon: "pi pi-fw pi-id-card", to: "/unidades" },
            { label: "Energia Injetada", icon: "pi pi-fw pi-id-card", to: "/energia" },
            { label: "Faturas", icon: "pi pi-fw pi-id-card", to: "/faturas" },


          ],
        },
        {
          items: [
            {
              label: "Sair",
              icon: "pi pi-fw pi-exclamation-circle",
              command: () => {
                this.axios
                  .post("/usuarios/sair")
                  .then(() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    this.$router.push("/");
                  })
                  .catch(() => {

                  });

              },
            },
          ],
        },
      ],

      adm_menu_fornecedor: [
        {
          label: "Workflow Compartilhamento",
          icon: "pi pi-fw pi-home",
          to: "/dashboard",
          items: [
            {
              label: "Home",
              icon: "pi pi-fw pi-home",
              to: "/dashboard",
            },
            
          ],
        },
        {
          label: "Configurações",
          items: [
            { label: "Usuários", icon: "pi pi-fw pi-user-edit", to: "/usuarios" },
          ],
        },

        {
          items: [
            {
              label: "Sair",
              icon: "pi pi-fw pi-exclamation-circle",
              command: () => {
                this.axios
                  .post("/usuarios/sair")
                  .then(() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    this.$router.push("/");
                  })
                  .catch(() => {

                  });

              },
            },
          ],
        },
      ],

      adm_menu_empresa: [
        {
          label: "Workflow Compartilhamento",
          icon: "pi pi-fw pi-home",
          to: "/dashboard",
          items: [
            {
              label: "Home",
              icon: "pi pi-fw pi-home",
              to: "/dashboard",
            },
            
          ],
        },
        {
          label: "Importação",
          icon: "pi pi-fw pi-sitemap",
          items: [

            { label: "Importar Planilha", icon: "pi pi-fw pi-file-pdf", to: "/importar-planilha" },
          ],
        },

        {
          label: "Configurações",
          items: [
            { label: "Usuários", icon: "pi pi-fw pi-user-edit", to: "/usuarios" },
          ],
        },

        {
          items: [
            {
              label: "Sair",
              icon: "pi pi-fw pi-exclamation-circle",
              command: () => {
                this.axios
                  .post("/usuarios/sair")
                  .then(() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    this.$router.push("/");
                  })
                  .catch(() => {

                  });

              },
            },
          ],
        },
      ],

      menu_fornecedor: [
        {
          label: "Workflow Compartilhamento",
          icon: "pi pi-fw pi-home",
          to: "/dashboard",
          items: [
            {
              label: "Home",
              icon: "pi pi-fw pi-home",
              to: "/dashboard",
            },
            
          ],
        },
        {
          label: "Importação",
          icon: "pi pi-fw pi-sitemap",
          items: [

            { label: "Importar Planilha", icon: "pi pi-fw pi-file-pdf", to: "/importar-planilha" },
            //{           label: "Faturas",            icon: "pi pi-fw pi-file-pdf",              to: "/faturas",            },
          ],
        },
        {
          items: [
            {
              label: "Sair",
              icon: "pi pi-fw pi-exclamation-circle",
              command: () => {
                this.axios
                  .post("/usuarios/sair")
                  .then(() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    this.$router.push("/");
                  })
                  .catch(() => {

                  });

              },
            },
          ],
        },
      ],

      menu_financeiro: [
        {
          label: "Workflow Compartilhamento",
          icon: "pi pi-fw pi-home",
          to: "/dashboard",
          items: [
            {
              label: "Home",
              icon: "pi pi-fw pi-home",
              to: "/dashboard",
            },
            
          ],
        },
        // {
        //   label: "Gestão",
        //   icon: "pi pi-fw pi-sitemap",
        //   items: [

        //   { label: "Gerenciar Pagamentos", icon: "pi pi-fw pi-file-pdf", to: "/liberar-pagamentos" },
        //     //{           label: "Faturas",            icon: "pi pi-fw pi-file-pdf",              to: "/faturas",            },
        //   ],
        // },
        {
          label: "Pagamentos",
          icon: "pi pi-fw pi-sitemap",
          items: [
           { label: "Validar Pagamentos", icon: "pi pi-fw pi-file-pdf", to: "/validar-pagamentos" },
           

          ],
        },
        {
          label: "Relatórios",
          icon: "pi pi-fw pi-sitemap",
          items: [
            { label: "Consolidação da Base", icon: "pi pi-fw pi-file-pdf", to: "/consolidacao" },
          ],
        },
        {
          items: [
            {
              label: "Sair",
              icon: "pi pi-fw pi-exclamation-circle",
              command: () => {
                this.axios
                  .post("/usuarios/sair")
                  .then(() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    this.$router.push("/");
                  })
                  .catch(() => {

                  });

              },
            },
          ],
        },
        
      ],

      menu_ame: [
        {
          label: "Workflow Compartilhamento",
          icon: "pi pi-fw pi-home",
          to: "/dashboard",
          items: [
            {
              label: "Home",
              icon: "pi pi-fw pi-home",
              to: "/dashboard",
            },
          ],
        },
        
        {
          label: "Importação",
          icon: "pi pi-fw pi-sitemap",
          items: [

            { label: "Importar Planilha", icon: "pi pi-fw pi-file-pdf", to: "/importar-planilha" },
            //{           label: "Faturas",            icon: "pi pi-fw pi-file-pdf",              to: "/faturas",            },
          ],
        },
        // {
        //   label: "Gestão",
        //   icon: "pi pi-fw pi-sitemap",
        //   items: [
        //    { label: "Gerenciar Pagamentos", icon: "pi pi-fw pi-file-pdf", to: "/liberar-pagamentos" },
           

        //   ],
        // },
        {
          label: "Pagamentos",
          icon: "pi pi-fw pi-sitemap",
          items: [
           { label: "Validar Pagamentos", icon: "pi pi-fw pi-file-pdf", to: "/validar-pagamentos" },
           

          ],
        },
        {
          label: "Relatórios",
          icon: "pi pi-fw pi-sitemap",
          items: [
            { label: "Consolidação da Base", icon: "pi pi-fw pi-file-pdf", to: "/consolidacao" },
          ],
        },
        {
          label: "Configurações",
          items: [
            { label: "Empresas", icon: "pi pi-fw pi-file-pdf", to: "/empresas" },
            { label: "Fornecedores", icon: "pi pi-fw pi-file-pdf", to: "/fornecedores" },
            { label: "Tipos de Planilha", icon: "pi pi-fw pi-file-pdf", to: "/tipos-planilhas" },
            { label: "Usuários", icon: "pi pi-fw pi-user-edit", to: "/usuarios" },
          ],
        },


        {
          items: [
            {
              label: "Sair",
              icon: "pi pi-fw pi-exclamation-circle",
              command: () => {
                this.axios
                  .post("/usuarios/sair")
                  .then(() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    this.$router.push("/");
                  })
                  .catch(() => {

                  });

              },
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo.svg";
    },
  },
  mounted() {
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    console.log(user);
    
    if (user.id_tipo == 22845207 && user.id_empresa == 22959227) {
      this.menu = this.adm_menu_empresa;
    }
    if (user.id_empresa == 22845207) {
      this.menu = this.menu_ame;
    }
    if (user.id_tipo == 22890835) {
      this.menu = this.adm_menu_fornecedor;
    }
    if (user.id_tipo == 22845207 && user.id_empresa == 22845207) {
      this.menu = this.menu_ame;
    }
    if (user.id_tipo == 22913699) {
      this.menu = this.menu_fornecedor;
    }
    if(user.id_tipo == 22959227) {
      this.menu = this.menu_financeiro;
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
